<div class="page-padding">
	<div class="alert alert-warning" *ngIf="error">
		{{ error }}
	</div>

	<div class="alert alert-info" *ngIf="!users && !error">Laddar...</div>

	<mat-card>
		<mat-card-header>
			<mat-card-title>Löneskuld</mat-card-title>
			<mat-card-subtitle
				>Innestående belopp baserat på totalt fakturerat arvode, oavsett
				om det är betalt eller ej.</mat-card-subtitle
			>
		</mat-card-header>
		<mat-card-content
			><table class="table" *ngIf="users">
				<tr>
					<td>&nbsp;</td>
					<td *ngFor="let year of years">{{ year }}</td>
				</tr>
				<tr *ngFor="let user of users">
					<td>
						<table-link path="salary" [entity]="user"></table-link>
					</td>
					<td *ngFor="let year of years">
						{{
							getSalaryRetained(user, year) | numberSv : "0" : "?"
						}}
					</td>
				</tr>
				<tr>
					<td>Summa</td>
					<td *ngFor="let year of years">
						{{ getSalaryRetainedSum(year) | numberSv : "0" : "?" }}
					</td>
				</tr>
			</table></mat-card-content
		>
	</mat-card>
</div>
