import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UsersService } from "core/users/users.service";
import { SalaryService } from "core/salary/salary.service";
import { Page } from "views/pages/Page";

@Component({
	selector: "user-salary-stats",
	templateUrl: "./user-salary-stats.component.html",
	styleUrls: ["./user-salary-stats.component.scss"],
})
export class UserSalaryStatsComponent extends Page implements OnInit {
	public users: any = [];
	public years: any = [];
	public error: string = null;

	constructor(
		private userService: UsersService,
		private salaryService: SalaryService,
		private http: HttpClient,
		private change: ChangeDetectorRef
	) {
		super();
	}

	ngOnInit() {
		this.loadUsers();
		let year = new Date().getFullYear();
		while (this.years.length < 4) {
			this.years.push(year);
			year--;
		}
	}

	private loadUsers() {
		let p = this.userService
			.loadUsers()
			.then((users: any[]) => {
				this.users = users;
			})
			.then(() => this.change.detectChanges())
			.then(() => this.loadDebt());
	}

	private loadDebt() {
		let p = Promise.resolve();
		for (let u of this.users) {
			p = p.then(() => {
				u.years = {};
			});
			for (let y of this.years) {
				p = p.then(() => this.loadUserYear(u, y));
			}
		}
		p = p.then(() => console.log("Loading done"));
		p.catch((error) => (this.error = error));
	}

	private loadUserYear(user: any, year: number): Promise<void> {
		return this.salaryService.getYear(user.id, year).then((data) => {
			user.years[data.year] = data;
			this.change.detectChanges();
			if (this.destroyed) {
				return Promise.reject("Component destroyed");
			}
			return Promise.resolve();
		});
	}

	public getSalaryRetained(user, year) {
		if (user.years && user.years[year]) {
			let y = user.years[year];
			return (y.salary.eligible ?? 0) - (y.salary.net ?? 0);
		}
		return null;
	}

	public getSalaryRetainedSum(year) {
		let sum = 0;
		for (let user of this.users) {
			if (user.years && user.years[year]) {
				let y = user.years[year];
				sum += (y.salary.eligible ?? 0) - (y.salary.net ?? 0);
			} else {
				return null;
			}
		}
		return sum;
	}
}
