import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: "user-commission-stats",
	templateUrl: "./user-commission-stats.component.html",
	styleUrls: ["./user-commission-stats.component.scss"],
})
export class UserCommissionStatsComponent implements OnInit {
	public teams: any = [];
	public totals: any = {};
	public error: string = null;
	public year = new Date().getFullYear();

	constructor(
		private http: HttpClient,
		private change: ChangeDetectorRef,
		private route: ActivatedRoute
	) {
		this.recalculate();
	}

	ngOnInit() {
		this.route.queryParams.subscribe((query) => this.navigate(query));
	}

	navigate(query: any) {
		let year = parseInt(query.year);
		if (year > 0) {
			this.year = year;
		}
		this.loadUsers();
	}

	private loadUsers() {
		let url = "/api/stats/teams?year=" + this.year;
		this.error = null;
		this.http.get(url).subscribe(
			(data: any[]) => {
				this.teams = data;
				this.recalculate();
				this.change.detectChanges();
			},
			(error) => {
				this.error = error.error;
			}
		);
	}

	private recalculate() {
		this.totals = {
			budget: 0,
			earned: 0,
			forecast: 0,
			itemsAvailable: 0,
			itemsRented: 0,
			itemsRentedThisYear: 0,
		};
		for (let t of this.teams) {
			t.percentage = this.getPercentage(t.earned, t.budget);
			for (let u of t.users) {
				u.percentage = this.getPercentage(u.earned, u.budget);
			}
			this.totals.budget += parseInt(t.budget ?? 0);
			this.totals.earned += parseInt(t.earned ?? 0);
			this.totals.forecast += parseInt(t.forecast ?? 0);
		}
		this.totals.percentage = this.getPercentage(
			this.totals.earned,
			this.totals.budget
		);
	}

	private getPercentage(earned: number, budget: number) {
		if (earned && budget) {
			return Math.round((100 * earned) / budget) + " %";
		} else {
			return "";
		}
	}
}
